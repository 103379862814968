import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './Admin/layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './Admin/layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'homeindex',
      pathMatch: 'full',
    }
    
    , {
      path: '',
      component: AuthLayoutComponent,
      children: [
      {
        path: 'Website',
        loadChildren: './Website/Website.module#WebsiteModule'
      },
      {
        path: '',
        loadChildren: './home/home.module#HomeModule'
      }
      ,
      {
        path: '',
        loadChildren: './Blogs/Blogs.module#BlogsModule'
      }
      ,
      {
        path: '',
        loadChildren: './ContactUs/ContactUs.module#ContactUsModule'
      }
      ,
      {
        path: '',
        loadChildren: './login/login.module#LoginModule'
      }
      ,
      {
        path: '',
        loadChildren: './SignUp/SignUp.module#SignUpModule'
      }
      ,
      {
        path: '',
        loadChildren: './Contentnotprovided/Contentnotprovided.module#ContentnotprovidedModule'
      }
      ,
      {
        path: '',
        loadChildren: './Terms/Terms.module#TermsModule'
      }
      ,
      {
        path: '',
        loadChildren: './Privacy/Privacy.module#PrivacyModule'
      }
      ,
      {
        path: '',
        loadChildren: './Help/Help.module#HelpModule'
      }
    ]

    }
    , {
      path: '',
      component: AuthLayoutComponent,
      children: [
      {
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      }]
    }
];
