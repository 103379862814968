import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders, HttpResponse ,HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import{Router} from '@angular/router';
import 'rxjs/add/operator/catch';
import { AppSettings } from '../appSetting'
import {Observable} from 'rxjs/Rx';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public token:string;

    constructor(private http: HttpClient) { }
    login(email: string, password: string) {
       var form={
            'email': email,
    'password': password,
    'device_type': 'android'
        }
        return this.http.post<any>(`${AppSettings.base_url}${AppSettings.autheticate_url}`, form)
        .catch((error) => {
            console.log(error.error.message);
            return Observable.throw(error.error.message);
          });

    }
    post(endpoint, body): Promise<any>
	{
		// this.token = JSON.parse(localStorage.getItem("currentUser")).auth_key;
		return this.http.post(`${AppSettings.base_url}` + endpoint, body)
			.toPromise().then((response: any) =>
			{
				if (response.status === 401) 
				{
					alert();
					localStorage.clear();
					window.location.href="login";
					window.location.reload();
				}
				else 
				{
					return response;
				}
			},
				(reason: any) =>
				{
					if (reason.error.status === 401) 
					{
						  localStorage.clear();
						  window.location.reload();
						return reason;
					}
					return reason;

				}).catch(this.handleError);
	}
    sendcode(phone) {
        return this.http.post<any>(`${AppSettings.base_url}${AppSettings.OTPautheticate_url}`, {'phone':phone })
        
        }
    otp(id,OTP) {
        return this.http.post<any>(`${AppSettings.base_url}${AppSettings.OTPautheticate_url}`, { id, OTP })
        .catch((error) => {
            console.log(error.error.message);
            return error.error.message;
          });
   
    }
    otpResend(id) {
        return this.http.post<any>(`${AppSettings.base_url}${AppSettings.OTPResentautheticate_url}`, {id})
        .catch((error) => {
            return error.error.message;
          });
     
    }
  
    logout() {
        // remove user from local storage to log user out 
        localStorage.removeItem('currentUser');
    }
  
    public handleError(error: any): Promise<any>
	{
		return error;
	}
}
